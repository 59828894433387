<template>
  <consent-v3 v-model="inputVal">
    <base-text as="p" variant="large-regular">
      <i18n path="consent.agree_text" :tag="false">
        <template #privacy>
          <router-link class="v3-anchor" :to="privacyPolicyUrl" target="_blank">{{
            $t('consent.privacy_policy')
          }}</router-link>
        </template>

        <template #tos>
          <router-link class="v3-anchor" :to="tosUrl" target="_blank">{{
            $t('consent.terms_of_service')
          }}</router-link>
        </template>
      </i18n>
    </base-text>
  </consent-v3>
</template>

<script>
import ConsentV3 from '@/components/ui-components/Consent/ConsentV3.vue'

export default {
  name: 'ConsentTerms',

  components: { ConsentV3 },

  props: {
    value: {
      type: Boolean,
      default: false
    },

    textClass: {
      type: String,
      default: ''
    }
  },

  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },

    privacyPolicyUrl() {
      return this.$i18nRoute({ name: 'Legal', hash: '#privacy' })
    },

    tosUrl() {
      return this.$i18nRoute({ name: 'Legal', hash: '#terms' })
    }
  }
}
</script>
