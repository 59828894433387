<template>
  <g>
    <path
      d="M12 4.25a7.751 7.751 0 0 0 0 15.5 7.75 7.75 0 1 0 0-15.5Zm0 14A6.246 6.246 0 0 1 5.75 12 6.248 6.248 0 0 1 12 5.75 6.248 6.248 0 0 1 18.25 12 6.246 6.246 0 0 1 12 18.25Zm3.351-7.975c0 2.095-2.263 2.128-2.263 2.902v.198a.375.375 0 0 1-.375.375h-1.426a.375.375 0 0 1-.375-.375v-.27c0-1.118.847-1.564 1.487-1.923.548-.308.885-.517.885-.924 0-.54-.688-.897-1.244-.897-.724 0-1.059.343-1.529.937a.375.375 0 0 1-.52.066l-.87-.66a.376.376 0 0 1-.083-.51C9.776 8.108 10.717 7.5 12.181 7.5c1.534 0 3.17 1.197 3.17 2.775ZM13.312 15.5c0 .724-.588 1.313-1.312 1.313a1.314 1.314 0 0 1-1.313-1.313c0-.724.59-1.313 1.313-1.313.724 0 1.313.59 1.313 1.313Z"
      fill="currentColor"
    />
  </g>
</template>

<script>
export default {
  name: 'IxIconQuestionMark'
}
</script>
