<template>
  <div class="input-autocomplete">
    <div class="input-autocomplete__input-field-wrap">
      <base-input
        :id="`autocomplete_${id}`"
        :value="inputVal"
        autocomplete="off"
        :list="id"
        :label="label"
        :placeholder="$t(placeholder)"
        css-for-error="input-error"
        css-for-input="ix-input-v3__input"
        css-for-label="ix-input-v3__label"
        class="ix-input-v3 mt-0 w-100"
        transition="fade-in-top"
        v-on="inputListeners"
      />

      <icon-base
        v-if="!inputVal.length"
        :params="{ width: '16', height: '16', viewBox: '0 0 1664 1664' }"
        class="input-autocomplete__search-icon"
      >
        <ix-icon-fa-search />
      </icon-base>

      <button v-else type="button" class="input-autocomplete__cancel-btn" @click="resetVal" />
    </div>

    <datalist v-if="!isLoading" :id="id">
      <option
        v-for="(item, index) in optionsData"
        :key="index"
        :value="$t(item[nameBy])"
        class="input-select__option"
      />
    </datalist>

    <transition v-if="errorMessage" name="fade-in-top">
      <div class="input-error">
        {{ $t(errorMessage) }}
      </div>
    </transition>
  </div>
</template>

<script>
import IconBase from '@/components/ui-components/IconBase/IconBase.vue'
import IxIconFaSearch from '@/components/icons/IxIconFaSearch.vue'

export default {
  name: 'InputAutocompleteV3',

  components: {
    IconBase,
    IxIconFaSearch
  },

  props: {
    value: {
      type: [Number, Object, String],
      default: null
    },

    question: {
      type: Object,
      required: true
    },

    valueBy: {
      type: String,
      default: 'id'
    },

    nameBy: {
      type: String,
      default: 'name'
    },

    optionsData: {
      type: [Number, Array],
      default: null
    },

    placeholder: {
      type: String,
      default: ''
    },

    validations: {
      type: Array,
      default: () => []
    },

    label: {
      type: String,
      default: ''
    },

    id: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      inputVal: '',
      listData: [],
      isLoading: false,
      revealedQuestions: []
    }
  },

  computed: {
    selectedAnswer() {
      return this.optionsData.find((option) => option.text === this.inputVal)
    },

    questionsToReveal() {
      return this.selectedAnswer?.reveal_question_ids ?? []
    },

    failedAnswer() {
      return this.selectedAnswer?.fail_if_selected === true
    },

    inputListeners() {
      const vm = this

      return Object.assign({}, this.$listeners, {
        input(value) {
          vm.updateVal(value)

          // Wait for computeds to evaluate after updateVal
          vm.$nextTick(() => {
            if (vm.failedAnswer) {
              vm.$emit('failed', {
                question: vm.question,
                answer: vm.selectedAnswer
              })

              return
            }

            if (vm.questionsToReveal.length > 0) {
              vm.$emit('hide-ids', vm.revealedQuestions)
              vm.$emit('reveal-ids', vm.questionsToReveal)
              vm.revealedQuestions = vm.questionsToReveal
            } else if (vm.revealedQuestions.length > 0) {
              vm.hideIds()
            }
          })
        }
      })
    },

    errorMessage() {
      return this.validations?.find((validation) => validation.constraint)?.message
    }
  },

  created() {
    // Freeze the optionsData to prevent making it reactive in the vue lifecycle
    this.listData = Object.freeze(this.optionsData)
  },

  methods: {
    hideIds() {
      this.$emit('hide-ids', this.revealedQuestions)
      this.revealedQuestions = []
    },

    resetVal() {
      this.inputVal = ''
      this.$emit('not-listed', false)
      this.$emit('input', this.inputVal)
      this.hideIds()
    },

    updateVal(val) {
      // Set loading to true. (prevents datalist from being rendered while DOM update is happening)
      this.isLoading = true
      let selectedVal = null
      for (let i = 0; i < this.listData.length; i++) {
        if (this.listData[i][this.nameBy] === val) {
          selectedVal = this.listData[i][this.valueBy]
          break
        }
      }

      this.$emit('not-listed', !selectedVal)
      this.$emit('input', selectedVal)

      this.$nextTick(() => {
        // Update the inputVal based on selected value
        this.inputVal = val
        this.isLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$search-icon-color: $v3-primary;
$search-icon-right: 45px;
$cancel-btn-bg-image: $cancel-icon;
$cancel-btn-width: 20px;
$cancel-btn-height: 20px;
$cancel-btn-right: 45px;

.input-autocomplete {
  &__input-field-wrap {
    display: flex;
    align-items: center;
  }

  &__search-icon {
    position: absolute;
    right: $search-icon-right;
    color: $search-icon-color;
  }

  &__cancel-btn {
    background-color: transparent;
    background-image: $cancel-btn-bg-image;
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    position: absolute;
    right: $cancel-btn-right;
    width: $cancel-btn-width;
    height: $cancel-btn-height;
  }
}
</style>
