import { ref } from 'vue'
import { isArray, mergeWith } from 'lodash-es'
import { transformToCamelCase } from '@/utils/objectUtils.js'

export function useForm() {
  const serverErrors = ref({})

  const getCombinedErrors = (inputValidations, errors) => {
    if (Object.keys(errors).length === 0) return inputValidations

    const customizer = (objValue, srcValue) => {
      if (isArray(objValue)) {
        return objValue.concat(srcValue)
      }
    }

    return mergeWith({}, transformToCamelCase(errors), inputValidations, customizer)
  }

  const getServerErrors = (errorResponseData) => {
    return Object.keys(errorResponseData).reduce((acc, field) => {
      acc[field] = [
        {
          constraint: true,
          message: errorResponseData[field]?.[0]
        }
      ]

      return acc
    }, {})
  }

  return {
    getCombinedErrors,
    getServerErrors,
    serverErrors
  }
}
