<template>
  <div class="input-address row">
    <base-input
      :id="`${id}_address`"
      :value="value.address"
      :disabled="disabled"
      autocomplete="street-address"
      :label="labelText($t(labels.address))"
      :placeholder="$t('form.address.placeholder.address')"
      :css-for-input="`ix-input-v3__input ${disabled ? 'ix-input-v3__input--disabled' : ''}`"
      css-for-label="ix-input-v3__label"
      class="ix-input-v3"
      :class="twoLinesAddress ? 'col-12 col-sm-6 px-3 px-md-2' : cssForCol"
      @input="(value) => update('address', value)"
    />

    <base-input
      v-if="showAddress2"
      :id="`${id}_address_2`"
      :value="value.address2"
      is-optional
      :disabled="disabled"
      autocomplete="street-address"
      :label="labelText($t(labels.address2))"
      :placeholder="$t('form.address.placeholder.address2')"
      :css-for-input="`ix-input-v3__input ${disabled ? 'ix-input-v3__input--disabled' : ''}`"
      css-for-label="ix-input-v3__label"
      class="ix-input-v3"
      :class="twoLinesAddress ? 'col-12 col-sm-6 px-3 px-md-2' : cssForCol"
      @input="(value) => update('address2', value)"
    />

    <base-input
      :id="`${id}_city`"
      :value="value.city"
      :disabled="disabled"
      autocomplete="address-level2"
      max-length="128"
      :label="labelText($t(labels.city))"
      :placeholder="$t('form.address.placeholder.city')"
      :css-for-input="`ix-input-v3__input ${disabled ? 'ix-input-v3__input--disabled' : ''}`"
      css-for-label="ix-input-v3__label"
      class="ix-input-v3"
      :class="cssForCol"
      @input="(value) => update('city', value)"
    />

    <input-select-v3
      :id="`${id}_state`"
      :value="value.state"
      :options-data="states"
      :label="labelText($t(labels.state))"
      :default-option="stateDefaultOption"
      :default-option-value="defaultOptionValue"
      :class="cssForCol"
      :disabled="disabled"
      value-by="abbreviation"
      @input="(value) => update('state', value)"
    />

    <base-input
      :id="`${id}_zip`"
      :value="value.zip"
      :disabled="disabled"
      autocomplete="postal-code"
      inputmode="numeric"
      :mask="['#####', '#####-####']"
      :is-model-masked="true"
      :label="labelText($t(labels.zip))"
      :placeholder="$t('form.address.placeholder.zip')"
      :css-for-input="`ix-input-v3__input ${disabled ? 'ix-input-v3__input--disabled' : ''}`"
      css-for-label="ix-input-v3__label"
      class="ix-input-v3 justify-content-end"
      :class="cssForCol"
      @input="(value) => update('zip', value)"
    />

    <transition v-if="errorMessage" :name="transition">
      <div :class="cssForError">{{ $t(errorMessage) }}</div>
    </transition>
  </div>
</template>

<script>
import InputSelectV3 from '@/components/ui-components/InputSelect/InputSelectV3.vue'
import common from '@/classes/common.js'

export default {
  name: 'InputAddressV3',
  components: {
    InputSelectV3
  },
  props: {
    cssForError: {
      type: [String, Array],
      default: ''
    },
    cssForCol: {
      type: String,
      default: 'col-12 col-md-4 px-3 px-md-2'
    },
    showAddress2: {
      type: Boolean,
      default: true
    },
    twoLinesAddress: {
      type: Boolean,
      default: false
    },
    defaultOptionValue: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    isFormDirty: {
      type: Boolean,
      default: false
    },
    labels: {
      type: Object,
      default: () => {
        return {
          address: 'form.address.float_label.address',
          address2: 'form.address.float_label.address2',
          city: 'form.address.float_label.city',
          state: 'form.address.float_label.state',
          zip: 'form.address.float_label.zip'
        }
      }
    },
    showRequiredStarOnLabel: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    stateDefaultOption: {
      type: String,
      default: ''
    },
    transition: {
      type: [String, Array],
      default: ''
    },
    validationObject: {
      type: Object,
      default: () => {}
    },
    value: {
      type: Object,
      default: () => {
        return {
          address: '',
          address2: '',
          city: '',
          state: '',
          zip: ''
        }
      }
    }
  },
  data() {
    return {
      states: common.filterStates()
    }
  },
  computed: {
    hasAllRequiredFields() {
      return Object.entries(this.value)
        .filter(([key]) => key !== 'address2')
        .every(([, value]) => value)
    },
    validations() {
      return [
        {
          constraint:
            this.isFormDirty &&
            this.validationObject.state?.$error &&
            this.validationObject.state?.isAvailable === false,
          message: 'validation.state.not_available'
        },
        {
          constraint:
            this.hasAllRequiredFields &&
            this.isFormDirty &&
            this.validationObject.zip?.$error &&
            !this.validationObject.zip?.pattern,
          message: 'validation.zip.pattern'
        },
        {
          constraint:
            this.hasAllRequiredFields &&
            this.isFormDirty &&
            this.validationObject.zip?.$error &&
            !this.validationObject.zip?.isValid,
          message: 'validation.address.valid'
        },
        {
          constraint:
            this.hasAllRequiredFields &&
            this.isFormDirty &&
            this.validationObject.address?.$error &&
            !this.validationObject.address?.maxLength,
          message: this.$t('validation.address.max_length', [256])
        },
        {
          constraint:
            this.hasAllRequiredFields &&
            this.isFormDirty &&
            this.validationObject.address2?.$error &&
            !this.validationObject.address2?.maxLength,
          message: this.$t('validation.address_2.max_length', [256])
        },
        {
          constraint:
            this.hasAllRequiredFields &&
            this.isFormDirty &&
            this.validationObject.city?.$error &&
            !this.validationObject.city?.maxLength,
          message: this.$t('validation.city.max_length', [128])
        },
        {
          constraint: this.isFormDirty && this.validationObject.$error,
          message: this.showAddress2
            ? 'validation.address.required_msg'
            : 'validation.address.required_no_address2_msg'
        }
      ]
    },
    errorMessage() {
      return this.validations?.find((validation) => validation.constraint)?.message
    }
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    labelText(text) {
      return `${text} ${this.showRequiredStarOnLabel ? '*' : ''}`
    }
  }
}
</script>

<style lang="scss" scoped>
$v3-input-error-message-color: $v3-color-red;
$v3-input-label-font-size: 13px;

.input-address {
  .input-error {
    color: $v3-input-error-message-color;
    font-size: $v3-input-label-font-size;
  }
}
</style>
