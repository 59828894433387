import { store } from '@/store/index.js'
import { CONSENT_TYPES } from '@/constants/consent.js'
import { STORE_NAMESPACE } from '@/constants/store.js'

export const hasAcceptedMandatoryConsents = (profile) => {
  const acceptedConsentTypes = profile?.consent?.map((consent) => consent.consent_type) || []

  return (
    acceptedConsentTypes.includes(CONSENT_TYPES.TERMS_OF_USE) &&
    acceptedConsentTypes.includes(CONSENT_TYPES.PRIVACY_POLICY)
  )
}

export const hasSignedConsent = (profile, consentType) => {
  return profile?.consent?.some(
    (consent) => consent.consent_type === consentType && consent.consent_signed_date !== null
  )
}

export const getOrderConsentPayloadData = () => {
  const consentStoreNamespace = STORE_NAMESPACE.CONSENTS
  const dataAuthorizationConsent =
    store.getters?.[`${consentStoreNamespace}/dataAuthorizationConsent`]
  const productStatusConsent = store.getters?.[`${consentStoreNamespace}/productStatusConsent`]

  return [
    {
      consent_type: dataAuthorizationConsent?.consentType?.toLowerCase(),
      cms_document_url: dataAuthorizationConsent?.documentUrl
    },
    {
      consent_type: productStatusConsent?.consentType?.toLowerCase(),
      cms_document_url: productStatusConsent?.documentUrl
    }
  ]
}
