<template>
  <input-date-v3
    :id="question.question_id"
    v-model="date"
    mode="date_mmddyyyyhhmm"
    :default-options="$t('form.date_of_birth.default_options')"
    :is-form-dirty="isFormDirty"
    :validation-object="validationObject"
    css-for-error="input-error"
    class="px-0 px-md-2"
    @input="$emit('input', date)"
  />
</template>

<script>
import InputDateV3 from '@/components/ui-components/InputDate/InputDateV3.vue'

export default {
  name: 'QuestionDateTime',

  components: {
    InputDateV3
  },

  props: {
    question: {
      type: Object,
      required: true
    },

    validationObject: {
      type: Object,
      default: () => ({})
    },

    isFormDirty: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      date: {
        month: '',
        day: '',
        year: '',
        hour: '',
        min: '',
        ms: ''
      }
    }
  }
}
</script>
