{
  "en": {
    "select_test": {
      "title": "Choose the screening option that fits your needs.",
      "cash_pricing": "Cash pricing",
      "insurance_pricing": "Insurance pricing",
      "product": {
        "not_available_description": "Not available due to provider availability, state regulations, or the selected pricing option.",
        "best_for_your_preferences": "Best for your preferences",
        "cash_pay": "cash pay",
        "due_now": "due now/claim filed later",
        "insurance_not_accepted": "Insurance not accepted",
        "learn_more": "Learn more",
        "select": "Select",
        "not_available": "Not available"
      },
      "hsa_fsa_info": "HSA/FSA accepted",
      "telehealth": {
        "text": "Prefer to speak with a clinician? {link}",
        "book_session": "Book a Telehealth session"
      },
      "consent": {
        "title": "You’re almost there.",
        "subtitle": "We keep your health information safe and secure. Please review and accept the terms below to continue.",
        "data_auth_consent_text": "I have read and consent to the Data Authorization above.",
        "combined_consent_text_prefix": "I have read and accept"
      },
      "error_fetching_products": "There was an error when we tried to get product list.",
      "error_fetching_eligibility_questionnaire": "There was an error when we tried to get eligibility questionnaire.",
      "error_fetching_eligibility_response": "There was an error when we tried to fetch eligibility response."
    }
  }
}
