<template>
  <base-text as="span" variant="caption-regular" class="hint ix-input-v3__hint">
    {{ hint || $t('form.hcp.share_results') }}
  </base-text>
</template>

<script>
export default {
  name: 'HCPHintShareResults',
  props: {
    hint: {
      type: String,
      default: ''
    }
  }
}
</script>

<style class="scss" scoped>
.hint {
  display: inline-block;
}
</style>
