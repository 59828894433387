import Vue from 'vue'
import Vuex from 'vuex'
import config from '@/store/config.js'
import consents from '@/store/consents.js'
import cms from '@/store/cms.js'
import feature from '@/store/feature.js'
import healthCareProviders from '@/store/healthCareProviders.js'
import integrationSetting from '@/store/integrationSetting.js'
import profile from '@/store/profile.js'
import products from '@/store/products.js'
import notifications from '@/store/notifications.js'
import insurance from '@/store/insurance.js'
import selectTest from '@/store/selectTest.js'
import surveys from '@/store/surveys.js'
import theme from '@/store/theme.js'

Vue.use(Vuex)

const debug = import.meta.env.DEV

export const store = new Vuex.Store({
  modules: {
    config,
    consents,
    cms,
    feature,
    healthCareProviders,
    integrationSetting,
    profile,
    products,
    notifications,
    insurance,
    selectTest,
    surveys,
    theme
  },
  strict: debug
})
