<template>
  <div class="box-v3" :class="{ 'box-v3--squared': squared, 'box-v3--transparent': transparent }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BoxV3',

  props: {
    squared: {
      type: Boolean,
      default: false
    },

    transparent: {
      type: Boolean,
      default: false
    }
  }
}
</script>
