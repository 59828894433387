<i18n src="./locales/lang.json"></i18n>

<template>
  <section>
    <base-heading as="h2" variant="semibold" class="mb-4">{{ $t('hcp.title') }}</base-heading>

    <box-v3 class="mb-4">
      <question-header :question="question" />
      <question-single-choice
        v-model="shareResults"
        :question="question"
        :validation-object="$v.shareResults"
      />
      <transition name="fade-in-down">
        <div v-if="isSharingResults" class="my-4">
          <header class="questionnaire-hcp__header">
            <base-heading as="h4" variant="medium">
              <span>{{ $t('hcp.details') }}</span>
            </base-heading>

            <button
              v-if="provider"
              type="button"
              class="v3-anchor"
              :disabled="!isFormDirty"
              @click="restoreOriginal"
            >
              <base-text as="span" variant="caption-regular">
                {{ $t('hcp.restore_original') }}
              </base-text>
            </button>
          </header>

          <hcp-form-edit
            ref="form"
            :provider="provider"
            :is-sharing-enabled="true"
            :is-form-disabled="!isEditable"
            is-required-for-order
            @update="(anyDirty) => (isFormDirty = anyDirty)"
          />
        </div>
      </transition>
    </box-v3>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import BoxV3 from '@/components/ui-components/Box/BoxV3.vue'
import QuestionHeader from './QuestionHeader.vue'
import QuestionSingleChoice from './QuestionSingleChoice.vue'
import HCPFormEdit from '@/components/views/Settings/partials/HCPFormEdit.vue'

const ANSWER_ID = {
  YES: 'Yes',
  NO: 'No'
}

export default {
  name: 'QuestionnaireHCP',

  components: { BoxV3, HcpFormEdit: HCPFormEdit, QuestionHeader, QuestionSingleChoice },

  props: {
    provider: {
      type: Object,
      default: null
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    products: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      shareResults: '',
      question: {
        question: this.$t('hcp.share_results'),
        description: '',
        question_id: 'share_results_with_hcp',
        required: true,
        question_answerchoices: [
          {
            text: this.$t('btn.yes'),
            fail_if_selected: false,
            answer_id: ANSWER_ID.YES
          },
          {
            text: this.$t('btn.no'),
            fail_if_selected: false,
            answer_id: ANSWER_ID.NO
          }
        ]
      },
      isFormDirty: false
    }
  },

  validations() {
    return {
      shareResults: {
        required
      }
    }
  },

  computed: {
    isSharingResults() {
      return this.shareResults === ANSWER_ID.YES
    }
  },

  watch: {
    provider: {
      handler(newValue) {
        if (this.shareResults !== '') return

        // Regardless of isEditable, if the PCP question is presented and any PCP info is filled, preselect Yes
        if (newValue && Object.values(newValue).some((val) => val)) {
          this.shareResults = ANSWER_ID.YES
        }
      },
      immediate: true
    }
  },

  mounted() {
    const productNames = this.products.map((product) => product.name).join(', ')
    this.question.description = this.$t('hcp.consent_text', { productNames })
  },

  methods: {
    validate() {
      this.$v.$touch()
      let isValid = !this.$v.$anyError

      if (this.isSharingResults) {
        this.$refs.form.$v.$touch()
        isValid &&= !this.$refs.form.$v.$anyError
      }

      return isValid
    },

    getHcpInformation(omittedFields = []) {
      return this.isSharingResults ? this.$refs.form.getHcpInformation(omittedFields) : null
    },

    isHCPResultSharingConsented() {
      return this.isSharingResults
    },

    restoreOriginal() {
      return this.$refs.form.restoreOriginal()
    }
  }
}
</script>

<style lang="scss" scoped>
.questionnaire-hcp {
  &__header {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
    }
  }
}
</style>
