<template>
  <base-checkbox v-model="inputVal" plain>
    <base-text v-if="acceptText" as="p">
      {{ acceptText }}
    </base-text>

    <slot v-else />
  </base-checkbox>
</template>

<script>
export default {
  props: {
    value: {
      default: false,
      type: Boolean
    },

    acceptText: {
      default: '',
      type: String
    }
  },

  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
