<template>
  <base-checkbox v-model="inputVal" plain>
    <base-text variant="large-regular">
      <i18n path="form.phone.receive_updates" :tag="false">
        <template #sms>
          <router-link
            class="v3-anchor"
            :to="$i18nRoute({ name: 'Legal', hash: '#sms' })"
            target="_blank"
          >
            <span>{{ $t('consent.sms') }}</span>
          </router-link>
        </template>
      </i18n>
    </base-text>
  </base-checkbox>
</template>

<script>
export default {
  name: 'SmsOptIn',

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
