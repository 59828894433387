<template>
  <div class="user-action">
    <div class="d-flex flex-column">
      <div class="d-flex flex-column align-items-stretch">
        <component
          :is="buttonType(btn)"
          v-for="(btn, index) in btns"
          :key="`btn-${index}`"
          :to="linkTo(btn)"
          :disabled="btn.isDisabled"
          :is-loading="btn.isLoading"
          :secondary="btn.secondary"
          :class="{
            'user-action__btn--underline': btn.hasUnderline
          }"
          class="justify-content-center mb-2"
          @click="btn.onClick"
        >
          <span>{{ $t(btn.title) || $t(btn.linkTitle) }}</span>
          <icon-base
            v-if="btn.icon"
            :params="{
              width: '24',
              height: '24',
              viewBox: '0 0 24 24',
              name: 'icons.ix-icon-forward'
            }"
            class="mx-0"
          >
            <ix-icon-forward />
          </icon-base>
        </component>
      </div>
    </div>
  </div>
</template>
<script>
import IxIconForward from '@/components/icons/IxIconForward.vue'
import IconBase from '@/components/ui-components/IconBase/IconBase.vue'
import BaseBtn from '@/components/ui-components/BaseBtn/BaseBtn.vue'
import GhostBtn from '@/components/ui-components/GhostBtn/GhostBtn.vue'

export default {
  name: 'UserActionV4',
  components: {
    GhostBtn,
    IxIconForward,
    IconBase
  },
  props: {
    btns: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    linkTo(btn) {
      return btn.routerLinkTo
        ? this.$i18nRoute({
            name: btn.routerLinkTo,
            params: btn.linkToParams,
            query: btn.linkToQuery
          })
        : ''
    },
    buttonType(btn) {
      return btn.isGhost ? GhostBtn : BaseBtn
    }
  }
}
</script>

<style lang="scss" scoped>
.user-action {
  &__btn {
    &--underline {
      text-decoration: underline;
    }
  }
}
</style>
