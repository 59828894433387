<i18n src="./lang.json"></i18n>

<template>
  <base-text as="p" variant="large-regular" class="warning-msg">
    <icon-base
      class="warning-msg__icon"
      :params="{
        width: '26',
        height: '26',
        viewBox: '0 0 30 31',
        name: 'icon.warning'
      }"
    >
      <ix-icon-warning />
    </icon-base>

    {{ $t(message, { orgName, support }) }}
  </base-text>
</template>

<script>
import IconBase from '@/components/ui-components/IconBase/IconBase.vue'
import IxIconWarning from '@/components/icons/IxIconWarning.vue'
import { getConfig } from '@/use/useConfig.js'
import { CONFIG } from '@/constants/config.js'
import { mapGetters } from 'vuex'
import { displayPhone, getPhone } from '@/utils/profileUtils.js'

export default {
  name: 'DisabledPatientProfileEditWarning',
  components: { IxIconWarning, IconBase },
  props: {
    message: {
      type: String,
      default: 'message'
    }
  },
  computed: {
    ...mapGetters(['organizationName']),
    orgName() {
      return this.organizationName || getConfig(CONFIG.COMPANY_NAME)
    },
    support() {
      let phone =
        getConfig(CONFIG.SUPPORT_PHONE).toString() || getConfig(CONFIG.COMPANY_PHONE).toString()
      phone = phone ? displayPhone(getPhone(phone)) : ''
      return phone || getConfig(CONFIG.SUPPORT_EMAIL) || this.$t('support')
    }
  }
}
</script>

<style scoped lang="scss">
.warning-msg {
  display: flex;
  align-items: center;
  color: $v3-text-color-dark-pale;

  &__icon {
    margin-right: 6px;
    margin-top: -2px;
    flex-shrink: 0;
    align-self: flex-start;
  }
}
</style>
