<template>
  <div>
    <base-input
      :value="value"
      input-type="email"
      inputmode="email"
      autocomplete="new-password"
      v-bind="$attrs"
      css-for-error="input-error"
      css-for-input="ix-input-v3__input"
      css-for-label="ix-input-v3__label"
      class="ix-input-v3"
      transition="fade-in-top"
      @input="(val) => $emit('input', val)"
    />

    <transition :name="transition">
      <div v-if="errorMessage" class="input-error">
        {{ $t(errorMessage) }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'QuestionEmail',

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      required: true
    },

    question: {
      type: Object,
      required: true
    },

    validations: {
      type: Array,
      default: () => []
    },

    transition: {
      type: [String, Array],
      default: 'fade-in-top'
    }
  },

  computed: {
    errorMessage() {
      return this.validations?.find((validation) => validation.constraint)?.message
    }
  }
}
</script>
