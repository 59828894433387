{
  "en": {
    "prescreen": {
      "top_card": {
        "title": {
          "badge": "New Order",
          "questionnaire": "Health History Pre-screening",
          "description": " ",
          "summary": "Summary"
        },
        "img_alt": "product image"
      },
      "login": {
        "question": "Already have an account?"
      },
      "popup_content": {
        "title": "Sorry{0},",
        "msg_1": "{product} is not right for you.",
        "generic": "You are not eligible for this test. Please see your health care provider for additional testing and guidance.",
        "life_threatening_condition": "You may be experiencing symptoms that require immediate medical attention. Please seek help from a clinician, urgent care, or emergency services before collecting your sample for this test.",
        "fail_by_pregnancy": "This thyroid function test is not appropriate for patients who are currently pregnant. Please see your health care provider for additional testing and guidance. You can contact CVS Health at <span class='text-nowrap'>1-800-SHOP-CVS</span> to receive a refund for your sample collection kit.",
        "fail_by_pregnancy_more_than_one_baby": "The Encompass Preeclampsia Test has not yet been validated in pregnancies with more than one baby (e.g. twins or triplets) so you are not eligible to proceed. <br/> <br/> Please email <a href='mailto:{supportEmail}'>{supportEmail}</a> for more information and to issue a refund. <br/> <br/> Please follow up with your healthcare team about your risk of developing preeclampsia. Additional information about signs and symptoms of preeclampsia can be found <a href='https://www.acog.org/womens-health/infographics/preeclampsia-and-pregnancy' target='_blank' rel='noopener noreferrer'>here</a>.",
        "not_eligible_for_asthma_test": "We're sorry, but it seems you're not eligible to participate in this screening. This screening is specifically for individuals with a prior asthma diagnosis. We recommend contacting your doctor for further advice and assistance.",
        "incorrect_patient_information": "Please update the form above with the patient’s legal information. The lab requires accurate patient information to process the screening. If incorrect information is provided, the lab may reject the submission, and no refund will be issued."
      },
      "error_popup": {
        "title": "Sorry, something went wrong.",
        "text": "Please check your questionnaire and then try again. If you believe this message is an error, please contact support at: <a href='mailto:{0}'>{0}</a>",
        "back_to_questionnaire": "Back to Health Questionnaire"
      },
      "eligibility": {
        "description": "Your answers will be shared with a licensed clinician to make sure the test(s) below are right for you. If the test(s) aren’t right for you, you will be notified with next steps.",
        "eligible_for": "You are eligible for the following free tests.",
        "contact_support": "Please contact {email} {phone} if you wish to change this order."
      }
    },
    "prescreen_questionnaire": {
      "box": {
        "title": "A few things before we get started."
      },
      "profile": {
        "title": "Please confirm your details",
        "description": " "
      },
      "guardian_consent": {
        "signed_out": "<strong>Ordering a test for your child?</strong> Enter your child’s information (each child requires a unique email). After you’ve entered your child’s information, enter your own information in the guardian section.",
        "signed_in": "<strong>Want to order a test for your child?</strong> Sign out of your own account. Create an account for your child. Then, order a test in their name."
      },
      "helperbox": {
        "title": "Basic Information",
        "subtitle": "Why are we asking these questions?",
        "paragraph": "These questions help clinicians learn more about your health for the purposes of test ordering."
      },
      "form_errors": {
        "age_restriction": "Sorry, you do not meet this test's age requirements.",
        "state_restriction": "Sorry, this test is not available in this state.",
        "help_message": " If you need help or wish to contact support, please <a href='{url}' target='_blank'>click here</a>."
      },
      "recaptcha_error_msg": "Sorry, Google reCAPTCHA verification failed."
    },
    "optional": "(Optional)"
  },
  "es": {}
}
