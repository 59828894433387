import { ref } from 'vue'

export function useOrderConsent() {
  const consentContent = ref([])
  const isDataAuthConsenChecked = ref(false)
  const isProductStatusConsentChecked = ref(false)
  const isLoading = ref(true)

  return {
    consentContent,
    isDataAuthConsenChecked,
    isProductStatusConsentChecked,
    isLoading
  }
}
