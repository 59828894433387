<i18n src="./locales/lang.json"></i18n>

<template>
  <header class="mb-4">
    <div class="questionnaire-form__question">
      <base-heading as="h4" :variant="variant">
        <span v-html="question.question" />

        <span v-if="isOptionalShown" class="questionnaire-form__question-optional">
          ({{ $t('optional') }})
        </span>
      </base-heading>
    </div>

    <base-text
      v-if="question.description"
      as="p"
      variant="large-regular"
      class="questionnaire-form__description"
      v-html="question.description"
    />
  </header>
</template>

<script>
export default {
  name: 'QuestionHeader',

  props: {
    question: {
      type: Object,
      required: true
    },

    variant: {
      type: String,
      default: 'medium'
    },

    shouldShowOptional: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    isOptionalShown() {
      return !this.question?.required && this.shouldShowOptional
    }
  }
}
</script>

<style lang="scss" scoped>
.questionnaire-form {
  &__question {
    margin-bottom: 16px;
  }

  &__question-optional {
    margin-left: 6px;
    color: $v3-color-hit-gray;
  }

  &__description {
    color: $v3-text-color-dark-pale;
  }
}
</style>
