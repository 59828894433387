<template>
  <div class="question-text">
    <input
      v-if="isSingleLine"
      type="text"
      :value="value"
      class="question-text__input ix-input-v3__input"
      v-bind="$attrs"
      @change="$emit('input', $event.target.value)"
    />

    <textarea
      v-else
      :value="value"
      class="question-text__textarea ix-input-v3__input w-100"
      v-bind="$attrs"
      @input="$emit('input', $event.target.value)"
    />

    <transition :name="transition">
      <div v-if="errorMessage" class="input-error">
        {{ $t(errorMessage) }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'QuestionText',

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      required: true
    },

    question: {
      type: Object,
      required: true
    },

    validations: {
      type: Array,
      default: () => []
    },

    transition: {
      type: [String, Array],
      default: 'fade-in-top'
    }
  },

  computed: {
    isSingleLine() {
      return this.question.type === 'text_single_line'
    },

    errorMessage() {
      return this.validations?.find((validation) => validation.constraint)?.message
    }
  }
}
</script>

<style lang="scss" scoped>
.question-text {
  &__textarea {
    padding: 16px;
    height: auto;
    resize: none;
    outline-offset: 0;
  }
}
</style>
