<template>
  <g>
    <g clip-path="url(#a)">
      <path
        stroke="#17B26A"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m6 8 1.333 1.333 3-3m1.6-3c.138.331.402.595.733.733l1.164.482a1.355 1.355 0 0 1 .733 1.77l-.481 1.163a1.353 1.353 0 0 0 0 1.038l.48 1.162a1.354 1.354 0 0 1-.733 1.771l-1.162.482c-.332.137-.596.4-.734.732l-.482 1.164a1.355 1.355 0 0 1-1.77.733l-1.163-.481a1.355 1.355 0 0 0-1.037 0l-1.163.482a1.355 1.355 0 0 1-1.77-.733l-.482-1.164a1.355 1.355 0 0 0-.732-.734l-1.164-.482a1.355 1.355 0 0 1-.734-1.77l.482-1.162a1.355 1.355 0 0 0 0-1.037l-.482-1.164a1.355 1.355 0 0 1 .734-1.771l1.163-.482c.331-.137.595-.4.733-.732l.482-1.163a1.355 1.355 0 0 1 1.77-.734l1.163.482c.332.137.705.137 1.037 0l1.164-.48a1.355 1.355 0 0 1 1.77.732l.482 1.164v-.002Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </g>
</template>

<script>
export default {
  name: 'IxIconCheckVerified'
}
</script>
