<template>
  <div class="ix-banner">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'IxBanner'
}
</script>

<style lang="scss" scoped>
$padding: 15px 30px;
$background: rgba($color-primary, 0.8);

.ix-banner {
  text-align: center;
  z-index: 15;
  padding: $padding;
  background: $background;

  &--info {
    background-color: $color-grey-lighter;
  }

  &--error {
    background-color: rgb(255 39 39 / 0.2);
    color: $color-warning;
  }
}
</style>
