<i18n src="./locales/lang.json"></i18n>

<template>
  <div class="select-test-consent container">
    <div class="row">
      <div class="col-12 col-lg-6 col-md-8 mx-auto">
        <header>
          <base-heading tag="h1" variant="semibold" class="select-test-consent__title">
            {{ $t('select_test.consent.title') }}
          </base-heading>

          <base-text as="p" class="select-test-consent__subtitle">
            {{ $t('select_test.consent.subtitle') }}
          </base-text>
        </header>

        <div v-if="isLoading" class="select-test-consent__spinner">
          <b-spinner variant="primary" />
        </div>

        <template v-else>
          <div class="select-test-consent__content-container">
            <base-text as="p" class="select-test-consent__content" v-html="consentContent" />
          </div>

          <div class="select-test-consent__consent-container">
            <consent-v3
              v-model="isDataAuthConsenChecked"
              :accept-text="$t('select_test.consent.data_auth_consent_text')"
            />

            <consent-v3
              v-if="productStatusConsent?.linkAppend && productStatusConsent?.documentUrl"
              v-model="isProductStatusConsentChecked"
              class="pt-2"
            >
              <base-text as="p">
                {{ $t('select_test.consent.combined_consent_text_prefix') }}

                <a
                  :href="productStatusConsent.documentUrl"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ productStatusConsent.linkAppend }}
                </a>
              </base-text>
            </consent-v3>
          </div>

          <user-action-v4 :value="userActionValue" :btns="userActionBtns" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { ANALYTICS_EVENT_GIVE_CONSENT } from '@/constants/analytics.js'
import { trackEvent } from '@/utils/analytics/events.js'
import { useOrderConsent } from '@/use/useConsent.js'
import { GET_ORDER_CONSENTS } from '@/store/consents.js'
import { STORE_NAMESPACE } from '@/constants/store.js'

import ConsentV3 from '@/components/ui-components/Consent/ConsentV3.vue'
import UserActionV4 from '@/components/ui-components/UserAction/UserActionV4.vue'

export default {
  name: 'SelectTestConsent',

  components: {
    ConsentV3,
    UserActionV4
  },

  setup() {
    const { consentContent, isDataAuthConsenChecked, isProductStatusConsentChecked, isLoading } =
      useOrderConsent()

    return {
      consentContent,
      isDataAuthConsenChecked,
      isProductStatusConsentChecked,
      isLoading
    }
  },

  headful() {
    return { title: this.$t('headful_title.prescreen_consent') }
  },

  computed: {
    ...mapGetters(STORE_NAMESPACE.CONSENTS, ['dataAuthorizationConsent', 'productStatusConsent']),

    userActionValue() {
      return this.isDataAuthConsenChecked && this.isProductStatusConsentChecked
    },

    isContinueBtnDisabled() {
      const hasNoContent = this.consentContent.length === 0
      return !this.isDataAuthConsenChecked || !this.isProductStatusConsentChecked || hasNoContent
    },

    userActionBtns() {
      return [
        {
          onClick: this.onContinueClick,
          isDisabled: this.isContinueBtnDisabled,
          title: 'btn.continue'
        }
      ]
    }
  },

  async created() {
    await this.$store.dispatch(`${STORE_NAMESPACE.CONSENTS}/${GET_ORDER_CONSENTS}`)
    this.consentContent = this.dataAuthorizationConsent?.htmlContent || []
    this.isLoading = false
  },

  methods: {
    onContinueClick() {
      // NOTE: The actual consent record is saved only after the order is created (aka the questionnaire submitted).
      trackEvent(ANALYTICS_EVENT_GIVE_CONSENT)

      this.$router
        .push({
          // TODO: Once the V4 Select Test Questionnaire is implemented and PrescreenConsentV3 is deprecated,
          // we can remove "consented: 1" from here. Currently, it is required to prevent redirection to OrderTestConsent.
          name: 'OrderTestQuestionnaire',
          query: {
            ...this.$route.query,
            consented: 1
          },
          params: this.$route.params
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
.select-test-consent {
  &__title {
    margin-bottom: $pharma-spacing-md;
    padding: 0;
  }

  &__subtitle {
    margin-bottom: $pharma-spacing-4xl;
    padding: 0;
  }

  &__spinner {
    display: flex;
    justify-content: center;
  }

  &__content-container {
    padding: $pharma-spacing-3xl;
    border-radius: $pharma-card-border-radius;
    background: $pharma-color-primary-dim1;
    margin-bottom: $pharma-spacing-xl;
  }

  &__consent-container {
    margin-bottom: $pharma-spacing-4xl;
  }
}
</style>
