<i18n src="./locales/lang.json"></i18n>

<template>
  <article
    class="select-test-product"
    :class="{ 'select-test-product--unavailable': product.is_unavailable }"
  >
    <figure v-if="image" class="select-test-product__figure">
      <img :src="image" :alt="title" class="select-test-product__img" />
      <recommended-badge v-if="isRecommended" class="select-test-product__badge">
        {{ $t('select_test.product.best_for_your_preferences') }}
      </recommended-badge>
    </figure>

    <div class="select-test-product__body">
      <template v-if="product.is_unavailable">
        <base-heading tag="h2" as="h5" variant="bold">
          {{ title }}
        </base-heading>

        <base-text v-if="product.is_unavailable" as="p" class="mt-3">
          {{ $t('select_test.product.not_available_description') }}
        </base-text>
      </template>

      <template v-else>
        <header class="select-test-product__header">
          <base-heading tag="h2" as="h5" variant="bold">
            {{ title }}
          </base-heading>

          <div class="select-test-product__price-container">
            <base-text v-if="price" class="select-test-product__price">
              <strong>{{ price }}</strong>
              {{ priceLabel }}
            </base-text>

            <icon-base
              v-if="tooltip"
              v-b-tooltip.hover
              :params="{ width: 24, height: 24, viewBox: '0 0 24 24' }"
              fill="none"
              :title="tooltip"
              class="select-test-product__tooltip"
            >
              <ix-icon-question-mark />
            </icon-base>
          </div>
        </header>

        <product-features v-if="features.length > 0" :features="features" />

        <ghost-btn class="select-test-product__learn-more" @click="handleMoreClick">
          {{ $t('select_test.product.learn_more') }}
        </ghost-btn>
      </template>
    </div>

    <base-btn v-if="product.is_unavailable" class="justify-content-center" disabled>
      {{ $t('select_test.product.not_available') }}
    </base-btn>

    <base-btn v-else class="justify-content-center" @click="handleSelect">
      {{ $t('select_test.product.select') }}
    </base-btn>

    <pharma-test-details-popup
      v-if="isPopupVisible && productDetails"
      :title="popupTitle"
      :content="productDetails.body"
      @close="isPopupVisible = false"
    />
  </article>
</template>

<script>
import { mapGetters } from 'vuex'
import zipState from 'zip-state'

import GhostBtn from '@/components/ui-components/GhostBtn/GhostBtn.vue'
import ProductFeatures from '@/components/views/SelectTestV4/ProductFeatures.vue'
import RecommendedBadge from '@/components/views/SelectTestV4/RecommendedBadge.vue'
import PharmaTestDetailsPopup from '@/components/ui-components/PharmaTestDetailsPopup/PharmaTestDetailsPopup.vue'
import IconBase from '@/components/ui-components/IconBase/IconBase.vue'
import IxIconQuestionMark from '@/components/icons/IxIconQuestionMark.vue'
import { trackButtonClick } from '@/utils/analytics/customEvents.js'
import {
  ANALYTICS_DIMENSION_PRODUCT_REPORTING_NAME,
  ANALYTICS_DIMENSION_TEST_NAME,
  ANALYTICS_EVENT_SELECT_TEST
} from '@/constants/analytics.js'
import { trackEvent } from '@/utils/analytics/events.js'

export default {
  name: 'SelectTestProductV4',

  components: {
    IxIconQuestionMark,
    IconBase,
    PharmaTestDetailsPopup,
    RecommendedBadge,
    ProductFeatures,
    GhostBtn
  },

  props: {
    product: {
      type: Object,
      required: true
    },

    dtcOrganization: {
      type: Object,
      required: true
    },

    testDetails: {
      type: Array,
      default: () => []
    },

    isRecommended: {
      type: Boolean,
      default: false
    },

    zip: {
      type: String,
      default: ''
    },

    isCommercialInsuranceSelected: {
      type: Boolean,
      default: false
    },

    eligibilityResponseUuid: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isPopupVisible: false
    }
  },

  computed: {
    ...mapGetters(['featureFlags']),

    productDetails() {
      const details = this.testDetails.find((detail) =>
        detail.tags.includes(`urn:cms:product:${this.product.slug}`)
      )

      return details ?? null
    },

    popupTitle() {
      return (
        this.productDetails?.body?.find((block) => block.type === 'heading')?.value ?? this.title
      )
    },

    preface() {
      return this.productDetails?.preface
    },

    image() {
      return this.preface?.image
    },

    title() {
      return this.preface?.title || this.product.name
    },

    isCoveredByInsurance() {
      return Boolean(this.insurancePrice)
    },

    priceKey() {
      return this.isCommercialInsuranceSelected ? 'insurance' : 'cash'
    },

    price() {
      if (this.isCommercialInsuranceSelected) {
        return this.insurancePrice || this.cashPrice
      }

      return this.cashPrice
    },

    priceLabel() {
      const override = this.priceLabelOverride?.[`${this.priceKey}_label`]
      if (override) return override

      let label = this.$t('select_test.product.cash_pay')
      if (this.isCommercialInsuranceSelected) {
        if (this.isCoveredByInsurance) {
          label = this.$t('select_test.product.due_now')
        } else {
          label += ` (${this.$t('select_test.product.insurance_not_accepted')})`
        }
      }

      return label
    },

    state() {
      return zipState(this.zip) || ''
    },

    priceLabelOverride() {
      const overrides = this.preface?.label_overrides || []
      if (overrides.length === 0) return null

      return overrides.find((item) => item.states.includes(this.state)) || null
    },

    cashPrice() {
      return this.preface?.cash_price
    },

    insurancePrice() {
      return this.preface?.insurance_price
    },

    tooltip() {
      const override = this.priceLabelOverride?.[`${this.priceKey}_tooltip`]
      if (override) return override

      return this.preface?.[`${this.priceKey}_tooltip`]
    },

    features() {
      return this.preface?.features ?? []
    },

    hasV4Consents() {
      return this.featureFlags?.enableV4Consents
    },

    to() {
      return {
        // TODO: After V4 consent is thoroughly tested and utilized, we can delete the OrderTestQuestionnaire route from here.
        name: this.hasV4Consents ? 'SelectTestConsent' : 'OrderTestQuestionnaire',
        params: {
          accessCode: this.dtcOrganization?.accessCode,
          orgId: this.dtcOrganization?.id,
          productId: this.product.id,
          eligibilityResponseUuid: this.eligibilityResponseUuid
        }
      }
    }
  },

  methods: {
    handleMoreClick() {
      trackButtonClick(this.$t('select_test.product.learn_more'), {
        [ANALYTICS_DIMENSION_TEST_NAME]: this.title,
        [ANALYTICS_DIMENSION_PRODUCT_REPORTING_NAME]: this.product.product_reporting_name ?? ''
      })
      this.isPopupVisible = true
    },

    handleSelect() {
      trackEvent(ANALYTICS_EVENT_SELECT_TEST, {
        [ANALYTICS_DIMENSION_TEST_NAME]: this.title ?? '',
        [ANALYTICS_DIMENSION_PRODUCT_REPORTING_NAME]: this.product.product_reporting_name ?? ''
      })

      this.$router.push(this.to).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
.select-test-product {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  border-radius: 16px;
  border: 2px solid #eaecf0;
  background-color: #fff;
  color: $pharma-color-text-primary;
  padding: $pharma-spacing-xl $pharma-spacing-3xl;

  @include media-breakpoint-up(md) {
    min-height: 518px;
  }

  &--unavailable {
    .select-test-product__figure {
      opacity: 0.2;
    }
  }

  &__figure {
    position: relative;
    margin: -1 * $pharma-spacing-xl -1 * $pharma-spacing-3xl $pharma-spacing-xl;
    height: 160px;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  &__badge {
    position: absolute;
    inset-block-start: $pharma-spacing-3xl;
    inset-inline-end: $pharma-spacing-3xl;
  }

  &__header {
    margin-block-end: $pharma-spacing-lg;
    padding-block-end: $pharma-spacing-lg;
    border-block-end: 1px solid $pharma-color-border-primary;
  }

  &__price-container {
    display: flex;
    align-items: center;
    gap: $pharma-spacing-xs;
  }

  &__price {
    font-size: $pharma-font-size-text-sm;

    strong {
      font-size: $pharma-font-size-text-md;
    }
  }

  &__tooltip {
    cursor: pointer;
  }

  &__body {
    flex: 1;
    margin-bottom: $pharma-spacing-lg;
  }

  &__learn-more {
    &,
    &:hover,
    &:focus {
      padding: 0;
    }

    text-decoration: underline;
  }
}
</style>
