<template>
  <div>
    <base-radio-group
      v-model="selected"
      :options="options"
      :name="question.question_id"
      :use-pharma-theming="usePharmaTheming"
      v-on="inputListeners"
    >
      <template #default="{ option }">
        <div v-if="!usePharmaTheming" v-html="option.label" />
        <select-card-content v-else :option="option" />
      </template>
    </base-radio-group>

    <transition :name="transition">
      <div v-if="validationObject.$error && !validationObject.required" class="input-error">
        {{ $t('validation.general.required_msg') }}
      </div>
    </transition>
  </div>
</template>

<script>
import SelectCardContent from '@/components/ui-components/SelectCard/SelectCardContent.vue'

export default {
  name: 'QuestionSingleChoice',
  components: { SelectCardContent },

  model: {
    event: 'change'
  },

  props: {
    value: {
      type: String,
      required: true
    },

    question: {
      type: Object,
      required: true
    },

    transition: {
      type: [String, Array],
      default: 'fade-in-top'
    },

    validationObject: {
      type: Object,
      default: () => ({})
    },

    usePharmaTheming: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selected: '',
      revealedQuestions: []
    }
  },

  computed: {
    options() {
      return this.question.question_answerchoices.map((option) => ({
        label: option.text,
        value: option.answer_id,
        description: option.description,
        iconUrl: option.icon_url
      }))
    },

    selectedAnswer() {
      return this.question.question_answerchoices.find(
        (answer) => answer.answer_id === this.selected
      )
    },

    questionsToReveal() {
      return this.selectedAnswer?.reveal_question_ids ?? []
    },

    failedAnswer() {
      return this.selectedAnswer?.fail_if_selected === true
    },

    inputListeners() {
      const vm = this

      return Object.assign({}, this.$listeners, {
        change() {
          vm.$emit('change', vm.selected)

          if (vm.failedAnswer) {
            vm.$emit('failed', {
              question: vm.question,
              answer: vm.selectedAnswer
            })

            return
          }

          if (vm.questionsToReveal.length > 0) {
            vm.$emit('hide-ids', vm.revealedQuestions)
            vm.$emit('reveal-ids', vm.questionsToReveal)
            vm.revealedQuestions = vm.questionsToReveal
          } else if (vm.revealedQuestions.length > 0) {
            vm.$emit('hide-ids', vm.revealedQuestions)
            vm.revealedQuestions = []
          }
        }
      })
    }
  },

  watch: {
    value: {
      handler(newValue) {
        this.selected = newValue
      },
      immediate: true
    }
  }
}
</script>
