<template>
  <path
    fill="transparent"
    stroke="#667085"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    d="M17 7.5H2m0-1.35v5.7c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.581.164h10.2c.84 0 1.26 0 1.581-.164a1.5 1.5 0 0 0 .655-.655c.164-.32.164-.74.164-1.581v-5.7c0-.84 0-1.26-.163-1.581a1.5 1.5 0 0 0-.656-.656c-.32-.163-.74-.163-1.581-.163H4.4c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656C2 4.889 2 5.309 2 6.15Z"
  />
</template>

<script>
export default {
  name: 'IxIconCard'
}
</script>
