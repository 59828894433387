import { ref } from 'vue'
import { required, requiredIf } from 'vuelidate/lib/validators'
import {
  city,
  date,
  day,
  decimal,
  email,
  factory,
  futureYear,
  integer,
  month,
  nextYear,
  phone,
  state,
  street,
  year,
  zip
} from '@/utils/validation.js'
import moment from 'moment'

export default ({ questionnaireData }) => {
  const extraQuestions = ref([])

  const flatQuestions = () => {
    return (questionnaireData.value?.questionnaire_sections || []).reduce(
      (acc, { section_questions }) => [...acc, ...section_questions],
      []
    )
  }

  const isQuestionVisible = (question) =>
    !question.is_hidden || extraQuestions.value.includes(question.question_id)

  const createValidationObject = ({ excludeDates = false, isStepBased = false } = {}) => {
    return flatQuestions()
      .filter(isQuestionVisible)
      .reduce((validations, question, questionIndex) => {
        let validationObj = {}
        if (question.type === 'address_full') {
          validationObj = {
            address: street(),
            city: city(),
            state: state(),
            zip: zip()
          }
        } else if (question.type === 'date_mmddyyyy') {
          validationObj = date({ excludeDates })
        } else if (question.type === 'date_mmddyyyy_next') {
          validationObj = {
            year: nextYear(),
            day: day(),
            month: month()
          }
        } else if (question.type === 'date_mmddyyyy_future') {
          validationObj = {
            year: futureYear(),
            day: day(),
            month: month(),
            date: factory({ minValue: (value) => moment(value).isSameOrAfter(moment(), 'day') })()
          }
        } else if (question.type === 'date_yyyy') {
          validationObj = {
            year: year()
          }
        } else if (question.type === 'phone') {
          validationObj = phone()
        } else if (question.type === 'email') {
          validationObj = email()
        } else if (question.type === 'integer') {
          const params = question.validation_params || {}
          validationObj = integer(params.min, params.max, question.required)
        } else if (question.type === 'decimal') {
          const params = question.validation_params || {}
          validationObj = decimal(params.min, params.max, question.required)
        }
        if (question.question_id === 'zip') {
          validationObj = zip()
        }
        if (question.required) {
          if (isStepBased) {
            validationObj.required = requiredIf(
              (model) => model.activeQuestionIndex === questionIndex
            )
          } else {
            validationObj.required = required
          }
        }

        validations[question.question_id] = validationObj

        return validations
      }, {})
  }

  const answerForm = () => {
    return flatQuestions().reduce((form, question) => {
      if (question.type === 'address_full') {
        form[question.question_id] = {
          address: '',
          city: '',
          state: '',
          zip: ''
        }
      } else if (question.type === 'date_yyyy') {
        form[question.question_id] = { year: null }
      } else if (
        question.type === 'date_mmddyyyy' ||
        question.type === 'date_mmddyyyy_next' ||
        question.type === 'date_mmddyyyy_future'
      ) {
        form[question.question_id] = {
          month: null,
          day: null,
          year: null
        }
      } else {
        form[question.question_id] = ''
      }

      return form
    }, {})
  }

  return {
    extraQuestions,
    flatQuestions,
    isQuestionVisible,
    createValidationObject,
    answerForm
  }
}
