<i18n src="./lang.json" />

<template>
  <div class="input-select-v3">
    <label
      v-if="label"
      :for="id"
      class="input-select-v3__label"
      :class="{ 'input-select-v3__label--no-break': labelNoBreak }"
    >
      {{ label }}
      <template v-if="isOptional">({{ $t('form.general.optional') }})</template>
      <sup v-if="tooltip" v-b-tooltip.hover :title="$t(tooltip)" class="input-select-v3__tooltip">
        ?
      </sup>
    </label>

    <multiselect
      v-model="inputVal"
      :multiple="multiple"
      :close-on-select="!multiple"
      :clear-on-select="false"
      :preserve-search="true"
      :placeholder="defaultOption"
      :label="optionsLabel"
      :track-by="trackBy"
      :preselect-first="false"
      :show-pointer="false"
      :deselect-label="$t('deselect')"
      :select-label="$t('select')"
      :options="optionsData"
      :custom-label="customLabel"
      v-bind="$attrs"
    >
      <template #selection="{ values, isOpen }">
        <span v-show="!isOpen" v-if="values.length" class="multiselect__single">
          {{ values.length }} {{ $t('selected') }}
        </span>
      </template>
    </multiselect>

    <slot name="append-container" />

    <transition :name="transition">
      <div v-if="errorMessage" :class="cssForError">{{ $t(errorMessage) }}</div>
    </transition>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'InputSelectMultipleV3',
  components: { Multiselect },
  props: {
    cssForError: {
      type: [String, Array],
      default: ''
    },
    cssForSelect: {
      type: String,
      default: ''
    },
    defaultOption: {
      default: '',
      type: String
    },
    id: {
      default: '',
      type: String
    },
    label: {
      default: '',
      type: String
    },
    isOptional: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: ''
    },
    nameBy: {
      default: 'name',
      type: String
    },
    optionsData: {
      default: null,
      type: [Number, Array]
    },
    optionsLabel: {
      type: String,
      default: 'text'
    },
    trackBy: {
      type: String,
      default: 'id'
    },
    multiple: {
      type: Boolean,
      default: true
    },
    requiredErrorMessage: {
      default: '',
      type: String
    },
    transition: {
      type: [String, Array],
      default: ''
    },
    validations: {
      type: [Array, Object],
      default: null
    },
    value: {
      default: null,
      type: [Number, Object, String, Array]
    },
    disabled: {
      default: false,
      type: Boolean
    },
    labelNoBreak: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    errorMessage() {
      return this.validations?.find((validation) => validation.constraint)?.message
    }
  },
  methods: {
    customLabel(option) {
      return this.$t(option[this.nameBy] || option)
    }
  }
}
</script>
