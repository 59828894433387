<template>
  <div class="row">
    <input-select-v3
      v-if="mode === 'date' || mode === 'date_mmddyyyyhhmm'"
      id="month"
      :value="value.month"
      :options-data="monthNames"
      :default-option="labelText($t('form.general.select'))"
      :default-option-value="defaultOptionValue"
      :label="label.month"
      :validations="inputValidations.month"
      :css-for-error="cssForError"
      :class="cssForCol"
      :transition="transition"
      :disabled="disabled"
      label-no-break
      @input="(value) => update('month', value)"
    />
    <input-select-v3
      v-if="mode === 'date' || mode === 'date_mmddyyyyhhmm'"
      id="day"
      :value="value.day"
      :options-data="dayRange"
      :label="label.day"
      :default-option="labelText($t('form.general.select'))"
      :default-option-value="defaultOptionValue"
      :validations="inputValidations.day"
      :css-for-error="cssForError"
      :class="cssForCol"
      :transition="transition"
      :disabled="disabled"
      label-no-break
      @input="(value) => update('day', value)"
    />
    <input-select-v3
      v-if="mode === 'date' || mode === 'year' || mode === 'date_mmddyyyyhhmm'"
      id="year"
      :value="value.year"
      :options-data="years"
      :label="label.year"
      :default-option="labelText($t('form.general.select'))"
      :default-option-value="defaultOptionValue"
      :validations="inputValidations.year"
      :css-for-error="cssForError"
      :class="cssForCol"
      :transition="transition"
      :disabled="disabled"
      label-no-break
      @input="(value) => update('year', value)"
    />

    <div v-if="mode === 'date_mmddyyyyhhmm'" class="col-12 px-3">
      <div class="row">
        <input-select-v3
          id="hour"
          :value="value.hour"
          :options-data="hourSamples"
          :label="$t('form.date.label.hour')"
          :default-option="labelText($t('form.general.select'))"
          :default-option-value="defaultOptionValue"
          class="col-12 col-md-4 px-3 px-md-2"
          @input="(value) => update('hour', value)"
        />
        <input-select-v3
          id="min"
          :value="value.min"
          :options-data="minSamples"
          :label="$t('form.date.label.min')"
          :default-option="labelText($t('form.general.select'))"
          :default-option-value="defaultOptionValue"
          class="col-12 col-md-4 px-3 px-md-2"
          @input="(value) => update('min', value)"
        />
        <input-select-v3
          id="ms"
          :value="value.ms"
          :options-data="msSamples"
          :label="$t('form.date.label.ms')"
          :default-option="labelText($t('form.general.select'))"
          :default-option-value="defaultOptionValue"
          class="col-12 col-md-4 px-3 px-md-2"
          @input="(value) => update('ms', value)"
        />
      </div>
    </div>

    <transition v-if="errorMessage" :name="transition">
      <div :class="cssForError">{{ $t(errorMessage) }}</div>
    </transition>
  </div>
</template>

<script>
import InputSelectV3 from '../InputSelect/InputSelectV3.vue'
import monthNames from '@/config/month_names.json'
import changeRangeDays from '@/classes/changeRangeDays.js'
import { isEqual } from 'lodash-es'
import moment from 'moment'

export default {
  name: 'InputDateV3',
  components: {
    InputSelectV3
  },
  props: {
    cssForError: {
      type: [String, Array],
      default: ''
    },
    cssForCol: {
      type: String,
      default: 'col-12 col-md-4 px-3 px-md-2'
    },
    defaultOptions: {
      type: Object,
      default: () => {
        return {
          month: '',
          day: '',
          year: '',
          hour: '',
          min: '',
          ms: ''
        }
      }
    },
    defaultOptionValue: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    isFormDirty: {
      type: Boolean,
      default: false
    },
    minYear: {
      type: [Object, Number],
      default: null
    },
    futureDateRange: {
      type: Number,
      default: 0
    },
    mode: {
      type: String,
      default: ''
    },
    monthDataArray: {
      type: Array,
      default: () => []
    },
    yearsDataArray: {
      type: Array,
      default: () => []
    },
    requiredErrorMessage: {
      type: String,
      default: ''
    },
    requiredErrorMessage2: {
      type: String,
      default: ''
    },
    showRequiredStarOnLabel: {
      type: Boolean,
      default: false
    },
    transition: {
      type: [String, Array],
      default: ''
    },
    validationObject: {
      type: Object,
      default: () => ({})
    },
    validations: {
      type: [Array, Object],
      default: null
    },
    value: {
      type: Object,
      default: null
    },
    disabled: {
      default: false,
      type: Boolean
    },
    birthdayLabel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      monthNames
    }
  },
  computed: {
    label() {
      return this.birthdayLabel ? this.$t('form.date.birthday_label') : this.$t('form.date.label')
    },
    dayRange() {
      return changeRangeDays(this.setMonth, this.setYear)
    },
    setMonth() {
      return this.value.month
    },
    setYear() {
      return this.value.year
    },
    years() {
      if (this.yearsDataArray.length) return this.yearsDataArray

      const year = new Date().getFullYear() + this.futureDateRange
      const pastRange = this.minYear ? this.minYear - 1 : 1900

      const years = Array.from({ length: year - pastRange }, (value, index) => year - index)
      return this.minYear ? years.reverse() : years
    },
    hourSamples() {
      return Array.from({ length: 12 }, (_, index) => index + 1)
    },
    minSamples() {
      return Array.from({ length: 60 }, (_, index) => `${index}`)
    },
    msSamples() {
      return ['AM', 'PM']
    },
    errorMessage() {
      return this.validations?.find((validation) => validation.constraint)?.message
    },
    inputValidations() {
      return {
        month: [
          {
            constraint:
              this.isFormDirty &&
              this.validationObject?.month?.$error &&
              !this.validationObject?.month?.required,
            message: 'validation.date_of_birth.required_msg.month'
          }
        ],
        day: [
          {
            constraint:
              this.isFormDirty &&
              this.validationObject?.day?.$error &&
              !this.validationObject?.day?.required,
            message: 'validation.date_of_birth.required_msg.day'
          }
        ],
        year: [
          {
            constraint:
              this.isFormDirty &&
              this.validationObject?.year?.$error &&
              !this.validationObject?.year?.required,
            message: 'validation.date_of_birth.required_msg.year'
          }
        ]
      }
    }
  },
  // if many nested values inside date object "value" updated at the same time, trigger once.
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (Object.values(newVal).some((value) => !!value) && !isEqual(newVal, oldVal)) {
          this.$emit('change', { ...newVal })
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    update(key, value) {
      const fullDate = moment({
        year: this.value.year,
        month: this.value.month,
        day: this.value.day,
        [key]: value
      }).toDate()
      this.$emit('input', { ...this.value, [key]: value, date: fullDate })
    },
    labelText(text) {
      return `${text} ${this.showRequiredStarOnLabel ? '*' : ''}`
    }
  }
}
</script>
