<template>
  <input-address-v3
    v-model="address"
    :state-default-option="$t('form.address.default_option.state')"
    class="px-0 px-md-2"
    @input="$emit('input', address)"
  />
</template>

<script>
import InputAddressV3 from '@/components/ui-components/InputAddress/InputAddressV3.vue'

export default {
  name: 'QuestionAddressFull',

  components: {
    InputAddressV3
  },

  props: {
    question: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      address: {
        address: null,
        state: null,
        city: null,
        zip: null
      }
    }
  }
}
</script>
