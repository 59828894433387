import { startCase } from 'lodash-es'
import IXLayerAPI from '@/classes/IXLayerAPI.js'
import { INSURANCE_OPTIONS } from '@/constants/selectTest.js'
import { omitBy, isNil } from 'lodash-es'
import { CONFIG } from '@/constants/config.js'
import { getConfig } from '@/use/useConfig.js'

const INSURANCE = 'insurance'

export function useEligibility() {
  const getQuestionnaire = async (orgId, uuid) => {
    const response = await IXLayerAPI.getEligibilityQuestionnaire(orgId, {
      rule: getConfig(CONFIG.ELIGIBILITY_QUESTIONNAIRE_RULE) || undefined,
      eligibility_response_uuid: uuid
    })

    return response.data
  }

  const loadEligibilityResponseSetByUuid = async (uuid, orgId) => {
    const response = await IXLayerAPI.getEligibilityQuestionnaireResponseByUuid(orgId, uuid)
    // The original responseSet has optional fields, which are loaded with null values from the API,
    // so we need to omit them to avoid errors when submitting the responseSet to the API (which does not accept null values
    // for these fields like the answer_text).
    const cleanedResponse =
      response.data?.eligibility_questionnaireresponse_questionresponseset?.map(
        ({ answer_id, answer_text, question_id }) =>
          omitBy({ answer_id, answer_text, question_id }, isNil)
      )
    return cleanedResponse
  }

  const prefetchUninsuredPriceOffering = async (responseSet, orgId) => {
    const insurance = responseSet.find((question) => question.question_id === INSURANCE)
    if (!insurance || insurance.answer_id !== INSURANCE_OPTIONS.COMMERCIAL) {
      return {
        success: false
      }
    }

    const offer = INSURANCE_OPTIONS.UNINSURED
    let newResponseSet = structuredClone(responseSet)
    const newInsuranceResponse = newResponseSet.find(
      (question) => question.question_id === INSURANCE
    )
    newInsuranceResponse.answer_id = offer
    newInsuranceResponse.answer_text = startCase(offer)

    newResponseSet = newResponseSet.filter(
      (response) => response.answer_text !== null || response.answer_id !== null
    )

    try {
      const response = await IXLayerAPI.submitEligibilityQuestionnaireResponse(orgId, {
        eligibility_questionnaireresponse_questionresponseset: newResponseSet
      })

      return {
        success: true,
        data: response.data,
        offer
      }
    } catch (err) {
      return {
        success: false,
        error: err.response?.data?.message || err
      }
    }
  }

  return {
    getQuestionnaire,
    prefetchUninsuredPriceOffering,
    loadEligibilityResponseSetByUuid
  }
}
