<i18n src="./locales/lang.json"></i18n>

<template>
  <i18n tag="base-text" as="p" class="telehealth-session" path="select_test.telehealth.text">
    <template #link>
      <a v-if="href" :href="href" class="v4-anchor" target="_blank">
        {{ $t('select_test.telehealth.book_session') }}
      </a>
      <ghost-btn v-else class="v4-anchor ml-1 p-0" @click="$emit('click')">
        {{ $t('select_test.telehealth.book_session') }}
      </ghost-btn>
    </template>
  </i18n>
</template>

<script>
import GhostBtn from '@/components/ui-components/GhostBtn/GhostBtn.vue'
export default {
  name: 'TelehealthSession',
  components: { GhostBtn },

  props: {
    href: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.telehealth-session {
  font-size: $pharma-font-size-text-md;

  @include media-breakpoint-up(md) {
    text-align: center;
  }
}
</style>
