<template>
  <g>
    <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
  </g>
</template>

<script>
export default {
  name: 'IxIconForward'
}
</script>
