import { store } from '@/store/index.js'
import { Trans } from '@/plugins/Translation.js'
import { getFallbackPath } from '@/utils/routeUtils.js'
import { i18n } from '@/plugins/i18n.js'
import { CONFIG } from '@/constants/config.js'
import SelectTestConsent from '@/components/views/SelectTestV4/SelectTestConsent.vue'
import SelectTestResources from '@/components/views/SelectTestV4/SelectTestResources.vue'
import SelectTestResults from '@/components/views/SelectTestV4/SelectTestResults.vue'
import SelectTestQuestionnaire from '@/components/views/SelectTestV4/SelectTestQuestionnaire.vue'

export default [
  {
    path: 'select-test',
    name: 'SelectTest',
    props: true,
    component: () =>
      store.getters.getConfig(CONFIG.SELECT_TEST_PAGE_VERSION) === 'V4'
        ? import('@/components/views/SelectTestV4/SelectTestRoot.vue')
        : import('@/components/views/SelectTest/SelectTest.vue'),
    beforeEnter: async (to, from, next) => {
      if (store.getters.featureFlags.enableSelectATest) {
        return next()
      }

      return next(`${Trans.defaultLanguage}/${getFallbackPath()}`)
    },
    redirect: { name: 'SelectTestQuestionnaire' },
    children: [
      {
        path: 'results/:eligibilityResponseUuid?',
        name: 'SelectTestResults',
        props: true,
        component: SelectTestResults,
        meta: {
          title: i18n.t('headful_title.select_a_test'),
          isFooterHidden: true
        }
      },
      {
        path: 'consent/:orgId/:productId/:eligibilityResponseUuid',
        name: 'SelectTestConsent',
        props: true,
        component: SelectTestConsent,
        meta: {
          title: i18n.t('headful_title.consent'),
          isFooterHidden: true
        }
      },
      {
        path: 'resources',
        name: 'SelectTestResources',
        component: SelectTestResources,
        meta: {
          title: i18n.t('headful_title.select_a_test'),
          isFooterHidden: true
        }
      },
      {
        path: ':eligibilityResponseUuid?/:questionId?',
        name: 'SelectTestQuestionnaire',
        component: SelectTestQuestionnaire,
        props: true,
        meta: {
          title: i18n.t('headful_title.select_a_test'),
          isFooterHidden: true,
          // These are workflow steps with $router.replace fallbacks. To avoid flashing, turn off the page transition animation
          pageTransition: 'none'
        }
      }
    ],
    meta: {
      title: i18n.t('headful_title.select_a_test'),
      isFooterHidden: () => store.getters.getConfig(CONFIG.SELECT_TEST_PAGE_VERSION) === 'V4',
      requireProfileWhenAuthenticated: true
    }
  }
]
