<template>
  <g>
    <g clip-path="url(#clip0_6843_105789)">
      <path
        d="M15 5.8125C9.65012 5.8125 5.3125 10.1517 5.3125 15.5C5.3125 20.8514 9.65012 25.1875 15 25.1875C20.3499 25.1875 24.6875 20.8514 24.6875 15.5C24.6875 10.1517 20.3499 5.8125 15 5.8125ZM15 23.3125C10.6823 23.3125 7.1875 19.8191 7.1875 15.5C7.1875 11.1838 10.6825 7.6875 15 7.6875C19.3161 7.6875 22.8125 11.1825 22.8125 15.5C22.8125 19.8176 19.3191 23.3125 15 23.3125ZM16.6406 19.25C16.6406 20.1546 15.9046 20.8906 15 20.8906C14.0954 20.8906 13.3594 20.1546 13.3594 19.25C13.3594 18.3454 14.0954 17.6094 15 17.6094C15.9046 17.6094 16.6406 18.3454 16.6406 19.25ZM13.4621 10.9921L13.7277 16.3046C13.7402 16.5541 13.9461 16.75 14.1959 16.75H15.8041C16.0539 16.75 16.2598 16.5541 16.2723 16.3046L16.5379 10.9921C16.5513 10.7244 16.3378 10.5 16.0697 10.5H13.9303C13.6622 10.5 13.4487 10.7244 13.4621 10.9921Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6843_105789">
        <rect width="20" height="20" fill="white" transform="translate(5 5.5)" />
      </clipPath>
    </defs>
  </g>
</template>

<script>
export default {
  name: 'IxIconWarning'
}
</script>
