<template>
  <div class="input-select-v3">
    <label
      v-if="label"
      :for="id"
      class="input-select-v3__label"
      :class="{ 'input-select-v3__label--no-break': labelNoBreak }"
    >
      {{ label }}

      <sup v-if="tooltip" v-b-tooltip.hover :title="$t(tooltip)" class="input-select-v3__tooltip">
        ?
      </sup>

      <base-required-indicator v-if="hasRequiredIndicator" />
    </label>

    <b-form-select
      v-model="inputVal"
      :disabled="disabled"
      :class="[
        'input-select-v3__select',
        { 'input-select-v3__select--default': inputVal === defaultOptionValue },
        cssForSelect
      ]"
    >
      <option v-if="defaultOption" :value="defaultOptionValue" :disabled="disableDefaultOption">
        {{ defaultOption }}
      </option>
      <option v-for="(item, index) in optionsData" :key="`id-${index}`" :value="getValue(item)">
        {{ $t(item[nameBy] || item) }}
      </option>
    </b-form-select>

    <slot name="append-container" />

    <transition :name="transition">
      <div v-if="errorMessage" :class="cssForError">{{ $t(errorMessage) }}</div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'InputSelectV3',

  props: {
    cssForError: {
      type: [String, Array],
      default: ''
    },
    cssForSelect: {
      type: String,
      default: ''
    },
    defaultOption: {
      default: '',
      type: String
    },
    defaultOptionValue: {
      default: '',
      type: String
    },
    disableDefaultOption: {
      type: Boolean,
      default: true
    },
    hasRequiredIndicator: {
      type: Boolean,
      default: false
    },
    id: {
      default: '',
      type: String
    },
    label: {
      default: '',
      type: String
    },
    tooltip: {
      type: String,
      default: ''
    },
    nameBy: {
      default: 'name',
      type: String
    },
    optionsData: {
      default: null,
      type: [Number, Array]
    },
    requiredErrorMessage: {
      default: '',
      type: String
    },
    transition: {
      type: [String, Array],
      default: ''
    },
    validations: {
      type: [Array, Object],
      default: null
    },
    value: {
      default: null,
      type: [Number, Object, String]
    },
    valueBy: {
      default: 'id',
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    },
    labelNoBreak: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    errorMessage() {
      return this.validations?.find((validation) => validation.constraint)?.message
    }
  },

  methods: {
    getValue(item) {
      return item[this.valueBy] ?? item
    }
  }
}
</script>
