import { errorHandler } from '@/utils/errorUtils.js'
import IXLayerAPI from '@/classes/IXLayerAPI.js'
import { i18n } from '@/plugins/i18n.js'
import { INSURANCE_OPTIONS } from '@/constants/selectTest.js'
import { CONFIG } from '@/constants/config.js'

export const SET_PROGRESS = 'SET_PROGRESS'
export const SET_IS_BACK_VISIBLE = 'SET_IS_BACK_VISIBLE'
export const SET_ELIGIBILITY_RESPONSE = 'SET_ELIGIBILITY_RESPONSE'
export const SET_ELIGIBILITY_QUESTIONNAIRE = 'SET_ELIGIBILITY_QUESTIONNAIRE'
export const SET_PRODUCTS = 'SET_PRODUCTS'

export const FETCH_ELIGIBILITY_QUESTIONNAIRE = 'FETCH_ELIGIBILITY_QUESTIONNAIRE'
export const FETCH_ELIGIBILITY_RESPONSE = 'FETCH_ELIGIBILITY_RESPONSE'
export const GET_PRODUCTS_BY_ELIGIBILITY_RESPONSE = 'GET_PRODUCTS_BY_ELIGIBILITY_RESPONSE'

const state = {
  questionnaire: null,
  eligibilityResponses: {},
  products: {
    default: [],
    [INSURANCE_OPTIONS.UNINSURED]: []
  },
  progress: 0,
  isBackVisible: false
}

const getters = {
  getEligibilityResponse: (state) => (uuid) => state.eligibilityResponses[uuid],
  products: (state) => state.products.default,
  uninsuredProducts: (state) => state.products[INSURANCE_OPTIONS.UNINSURED],
  progress: (state) => state.progress,
  questionnaire: (state) => state.questionnaire,
  isBackVisible: (state) => state.isBackVisible
}

const actions = {
  [SET_PROGRESS]: ({ commit }, value) => {
    commit(SET_PROGRESS, value)
  },

  [SET_IS_BACK_VISIBLE]: ({ commit }, value) => {
    commit(SET_IS_BACK_VISIBLE, value)
  },

  [FETCH_ELIGIBILITY_QUESTIONNAIRE]: async ({ rootState, rootGetters, commit }, uuid) => {
    try {
      const { data } = await IXLayerAPI.getEligibilityQuestionnaire(
        rootState.profile.orgByAccessCode.id,
        {
          rule: rootGetters.getConfig(CONFIG.ELIGIBILITY_QUESTIONNAIRE_RULE) || undefined,
          eligibility_response_uuid: uuid
        }
      )

      commit(SET_ELIGIBILITY_QUESTIONNAIRE, data)

      return data
    } catch (err) {
      errorHandler({
        toastedMessage: i18n.t('select_test.error_fetching_eligibility_questionnaire'),
        sentryErrMessage: `FAILED DUE TO ${err.response?.data?.message || err}`
      })
    }
  },

  [FETCH_ELIGIBILITY_RESPONSE]: async ({ rootState, commit }, uuid) => {
    try {
      const { data } = await IXLayerAPI.getEligibilityQuestionnaireResponseByUuid(
        rootState.profile.orgByAccessCode.id,
        uuid
      )

      commit(SET_ELIGIBILITY_RESPONSE, {
        uuid,
        responseSet: data.eligibility_questionnaireresponse_questionresponseset
      })

      return data.eligibility_questionnaireresponse_questionresponseset
    } catch (err) {
      errorHandler({
        toastedMessage: i18n.t('select_test.error_fetching_eligibility_response'),
        sentryErrMessage: `FAILED DUE TO ${err.response?.data?.message || err}`
      })
    }
  },

  [SET_ELIGIBILITY_RESPONSE]: ({ commit }, { uuid, responseSet }) => {
    commit(SET_ELIGIBILITY_RESPONSE, {
      uuid,
      responseSet
    })

    return responseSet
  },

  [GET_PRODUCTS_BY_ELIGIBILITY_RESPONSE]: async (
    { rootState, commit },
    { uuid, productsKey = 'default' }
  ) => {
    try {
      const { data } = await IXLayerAPI.getProductList({
        with_rules: true,
        organization: rootState.profile.orgByAccessCode.id,
        eligibility_response_uuid: uuid
      })

      commit(SET_PRODUCTS, { key: productsKey, value: data.results })
    } catch (err) {
      errorHandler({
        toastedMessage: i18n.t('select_test.error_fetching_products'),
        sentryErrMessage: `FAILED DUE TO ${err.response?.data?.message || err}`
      })
    }
  }
}

const mutations = {
  [SET_PROGRESS]: (state, value) => {
    state.progress = value
  },

  [SET_IS_BACK_VISIBLE]: (state, value) => {
    state.isBackVisible = value
  },

  [SET_ELIGIBILITY_RESPONSE]: (state, { uuid, responseSet }) => {
    state.eligibilityResponses[uuid] = responseSet
  },

  [SET_ELIGIBILITY_QUESTIONNAIRE]: (state, value) => {
    state.questionnaire = value
  },

  [SET_PRODUCTS]: (state, { key, value }) => {
    state.products[key] = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
