<template>
  <base-input
    :id="question.question_id"
    input-type="number"
    :value="value"
    :placeholder="placeholder"
    :validations="validations"
    :label="label"
    css-for-error="input-error"
    css-for-input="ix-input-v3__input"
    css-for-label="ix-input-v3__label"
    class="ix-input-v3"
    transition="fade-in-top"
    autocomplete="off"
    v-bind="$attrs"
    @input="(val) => $emit('input', val)"
  />
</template>

<script>
export default {
  name: 'QuestionNumber',
  props: {
    value: {
      type: String,
      required: true
    },

    question: {
      type: Object,
      required: true
    },

    placeholder: {
      type: String,
      default: ''
    },

    validations: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    label() {
      return this.$t(this.question.label) || ''
    }
  }
}
</script>
