{
  "en": {
    "settings": {
      "navigation": {
        "title": "Settings",
        "profile": "Profile",
        "primary_care_physician": "@:hcp.capitalized",
        "insurance": "Insurance",
        "guardian": "Guardian",
        "notifications": "Notifications",
        "security": "Security"
      },
      "profile": {
        "title": "Profile"
      },
      "guardian": {
        "title": "Guardian",
        "add": "Add a guardian",
        "remove": "Remove guardian",
        "delete_popup": {
          "title": "Are you sure?",
          "text": "Press ‘Confirm’ if you are sure you would like to delete all these guardian information."
        }
      },
      "hcp": {
        "personal": "Personal",
        "contact": "Contact",
        "add": "Add @:hcp.lowercase",
        "disabled": "Adding @:hcp.lowercase information is disabled for your organization.",
        "delete": "Delete provider",
        "append_title_primary": " - Default provider",
        "address2_optional": "Address 2 (Optional)",
        "fax_or_address_required": "Address or fax is required",
        "update_success": "Default provider updated successfully",
        "delete_success": "Provider was successfully deleted",
        "checkbox": {
          "make_default": "Make default"
        },
        "popup": {
          "delete": {
            "non_default_provider": {
              "title": "Are you sure?",
              "text": "Press 'Confirm' to delete '{name}'. You will no longer be able to send result to this provider. Results eFaxed previously (if any) will not be affected."
            },
            "default_provider": {
              "title": "Delete default health care provider",
              "text": "You must choose a new default health care provider to continue to automatically keep a provider up to date on your test results.",
              "dropdown_title": "Please choose a new default health care provider.",
              "label": "Provider"
            }
          },
          "save": {
            "title": "Change default health care provider",
            "text": "{name} will be set as your default provider. If you have auto-share test result with your provider turned on, the default provider is who the test result will be sent to."
          }
        }
      },
      "notifications": {
        "title": "Notifications"
      },
      "security": {
        "title": "Security",
        "subtitle": "Change password",
        "reset_btn": "Reset password",
        "confirm_message": "An email with instructions to reset your password has been sent."
      }
    }
  }
}
