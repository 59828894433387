<template>
  <path
    d="M11.7531 16.7531C11.4875 17.0719 11.0125 17.0719 10.7188 16.7531L8.71875 14.7531C8.42812 14.4875 8.42812 14.0125 8.71875 13.7188C9.0125 13.4281 9.4875 13.4281 9.75313 13.7188L11.25 15.1906L14.2188 12.2188C14.5125 11.9281 14.9875 11.9281 15.2531 12.2188C15.5719 12.5125 15.5719 12.9875 15.2531 13.2531L11.7531 16.7531ZM9 4C9.41563 4 9.75 4.33594 9.75 4.75V6H14.25V4.75C14.25 4.33594 14.5844 4 15 4C15.4156 4 15.75 4.33594 15.75 4.75V6H17C18.1031 6 19 6.89531 19 8V18C19 19.1031 18.1031 20 17 20H7C5.89531 20 5 19.1031 5 18V8C5 6.89531 5.89531 6 7 6H8.25V4.75C8.25 4.33594 8.58437 4 9 4ZM17.5 10H6.5V18C6.5 18.275 6.72375 18.5 7 18.5H17C17.275 18.5 17.5 18.275 17.5 18V10Z"
    fill="currentColor"
  />
</template>

<script>
export default {
  name: 'IxIconCalendarCheck'
}
</script>
