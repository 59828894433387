{
  "en": {
    "hcp": {
      "title": "@:hcp.capitalized Information",
      "details": "@:hcp.capitalized details",
      "restore_original": "Restore original",
      "share_results": "Would you like to share your test results with your @:hcp.lowercase?",
      "consent_text": "By selecting Yes, you consent to share your { productNames } results via fax or mail with your Health care provider(s)."
    },
    "optional": "optional"
  }
}
