<template>
  <portal to="below-page" :disabled="isPortalDisabled">
    <div
      class="user-action"
      :class="value ? `user-action__btns-wrap--active` : `user-action__btns-wrap`"
    >
      <div v-if="hasAuxBtn && !isHorizontal" class="d-flex justify-content-center">
        <div class="d-inline-flex pt-3">
          <ghost-btn
            :to="linkTo(auxBtn)"
            :disabled="auxBtn.isDisabled"
            :is-loading="auxBtn.isLoading"
            active
            @click="auxBtn.onClick"
          >
            <span>{{ $t(auxBtn.title) || $t(auxBtn.linkTitle) }}</span>
          </ghost-btn>
        </div>
      </div>
      <div class="d-flex flex-column">
        <div class="d-flex flex-row-reverse justify-content-between px-2 px-md-5 py-3 py-md-0">
          <div
            v-for="(btn, index) in btns"
            :key="`btn-${index}`"
            class="d-flex flex-column flex-md-row align-items-stretch my-1 py-md-2"
            :class="{
              'col-3 col-md-6 justify-content-start': index === 1 && btns.length > 1,
              'col-9 col-md-6 justify-content-end': index !== 1 && btns.length > 1,
              'col-12 justify-content-end': btns.length === 1
            }"
          >
            <ghost-btn
              v-if="isHorizontal && hasAuxBtn && index == 0"
              :to="linkTo(auxBtn)"
              :disabled="auxBtn.isDisabled"
              :is-loading="auxBtn.isLoading"
              active
              @click="auxBtn.onClick"
            >
              <span>{{ $t(auxBtn.title) || $t(auxBtn.linkTitle) }}</span>
            </ghost-btn>

            <component
              :is="buttonType(btn)"
              :to="linkTo(btn)"
              :disabled="btn.isDisabled"
              :is-loading="btn.isLoading"
              :active="value || btn.isGhostActivated"
              :secondary="btn.secondary"
              :class="{
                'user-action__btn--active': !btn.isGhost && !btn.isDisabled && !btn.isBtnActivated
              }"
              class="justify-content-center"
              @click="btn.onClick"
            >
              <span>{{ $t(btn.title) || $t(btn.linkTitle) }}</span>
              <icon-base
                v-if="btn.icon"
                :params="{
                  width: '24',
                  height: '24',
                  viewBox: '0 0 24 24',
                  name: 'icons.ix-icon-forward'
                }"
                class="mx-0"
              >
                <ix-icon-forward />
              </icon-base>
            </component>
          </div>
        </div>
      </div>
    </div>
  </portal>
</template>
<script>
import { Portal } from 'portal-vue'
import IxIconForward from '@/components/icons/IxIconForward.vue'
import IconBase from '@/components/ui-components/IconBase/IconBase.vue'
import BaseBtn from '@/components/ui-components/BaseBtn/BaseBtn.vue'
import GhostBtn from '@/components/ui-components/GhostBtn/GhostBtn.vue'
import { isEmpty } from 'lodash-es'

export default {
  name: 'UserActionV3',
  components: {
    GhostBtn,
    IxIconForward,
    IconBase,
    Portal
  },
  props: {
    value: {
      type: Boolean,
      default: true
    },
    btns: {
      type: Array,
      default: () => []
    },
    auxBtn: {
      type: Object,
      default: () => ({})
    },
    onClick: {
      type: Function,
      default: null
    },
    routerLinkTo: {
      type: String,
      default: ''
    },
    isPortalDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasAuxBtn() {
      return !isEmpty(this.auxBtn)
    },
    isHorizontal() {
      return window.innerWidth > 992
    }
  },
  methods: {
    linkTo(btn) {
      return btn.routerLinkTo
        ? this.$i18nRoute({
            name: btn.routerLinkTo,
            params: btn.linkToParams,
            query: btn.linkToQuery
          })
        : ''
    },
    buttonType(btn) {
      return btn.isGhost ? GhostBtn : BaseBtn
    }
  }
}
</script>
<style lang="scss" scoped>
$z-index: 21;
$btns-wrap-bg-color: $v3-color-white;
$btns-wrap-bg-color-active: $v3-primary;

.user-action {
  margin-top: 24px;

  @include media-breakpoint-up(lg) {
    margin-top: 40px;
  }

  &--relative {
    position: relative;
  }

  &__btns-wrap {
    background-color: $btns-wrap-bg-color;

    &--active {
      background-color: $btns-wrap-bg-color-active;
    }
  }

  &__btn {
    &--active {
      &,
      &:hover,
      &:active,
      &:focus {
        background-color: $v3-color-white;
        color: $v3-primary;
      }
    }
  }
}
</style>
