import { i18n } from '@/plugins/i18n.js'

import { getConfig } from '@/use/useConfig.js'
import { CONFIG } from '@/constants/config.js'
import { CONTENT_TYPES, CONSENT_TYPES } from '@/constants/content.js'
import { Trans } from '@/plugins/Translation.js'
import { errorHandler } from '@/utils/errorUtils.js'
import common from '@/classes/common.js'

export const GET_ORDER_CONSENTS = 'GET_ORDER_CONSENTS'
const SET_DATA_AUTHORIZATION_CONSENT = 'SET_DATA_AUTHORIZATION_CONSENT'
const SET_PRODUCT_STATUS_CONSENT = 'SET_PRODUCT_STATUS_CONSENT'
export const RESET_ORDER_CONSENTS = 'RESET_ORDER_CONSENTS'

const fetchDataAuthConsent = async (cmsApiInstance, featureFlags) => {
  const contenttypeConsents = CONTENT_TYPES.CONSENTS.toLowerCase().replace(/_/g, '-')
  const consenttypeDataAuthorization = CONSENT_TYPES.DATA_AUTHORIZATION.toLowerCase().replace(
    /_/g,
    '-'
  )

  const res = await cmsApiInstance.get('consent/', {
    params: {
      org_content_id: getConfig(CONFIG.ORG_CONTENT_ID),
      tags: `urn:cms:${contenttypeConsents}:${consenttypeDataAuthorization}`,
      limit: 1,
      live: true,
      locale: Trans.currentLanguage,
      unpublished: featureFlags.cmsShowUnpublished || false
    }
  })

  const documentUrl = res?.data?.items?.[0]?.meta?.document
  if (!documentUrl) return null

  const fetchedConstentResult = await fetch(documentUrl)
  const fetchedConstentResultTest = await fetchedConstentResult.text()

  return {
    consentType: CONSENT_TYPES.DATA_AUTHORIZATION,
    documentUrl,
    htmlContent: fetchedConstentResultTest
  }
}

const fetchProductStatusConsent = async (cmsApiInstance, featureFlags) => {
  const contenttypeConsents = CONTENT_TYPES.CONSENTS.toLowerCase().replace(/_/g, '-')
  const constenttypeProductStatus = CONSENT_TYPES.PRODUCT_STATUS.toLowerCase().replace(/_/g, '-')

  const res = await cmsApiInstance.get('consent/', {
    params: {
      org_content_id: getConfig(CONFIG.ORG_CONTENT_ID),
      tags: `urn:cms:${contenttypeConsents}:${constenttypeProductStatus}`,
      limit: 1,
      live: true,
      locale: Trans.currentLanguage,
      unpublished: featureFlags.cmsShowUnpublished || false
    }
  })

  const productStatusConsentResult = res?.data?.items?.[0]?.meta
  if (!productStatusConsentResult) return null

  return {
    consentType: CONSENT_TYPES.PRODUCT_STATUS,
    documentUrl: productStatusConsentResult.document,
    linkAppend: productStatusConsentResult.body
  }
}

const initialDataAuthorizationConsentState = () => ({ dataAuthorizationConsent: {} })
const initialProductStatusConsentState = () => ({ productStatusConsent: {} })

const state = {
  ...initialDataAuthorizationConsentState(),
  ...initialProductStatusConsentState()
}

const getters = {
  dataAuthorizationConsent: (state) => state.dataAuthorizationConsent,
  productStatusConsent: (state) => state.productStatusConsent
}

const actions = {
  [GET_ORDER_CONSENTS]: async ({ commit, rootGetters }) => {
    const cmsApiInstance = rootGetters.cmsApiInstance
    const featureFlags = rootGetters.featureFlags

    try {
      const [dataAuthConsentRes, productStatusConsentRes] = await Promise.all([
        fetchDataAuthConsent(cmsApiInstance, featureFlags),
        fetchProductStatusConsent(cmsApiInstance, featureFlags)
      ])

      commit('SET_DATA_AUTHORIZATION_CONSENT', dataAuthConsentRes)
      commit('SET_PRODUCT_STATUS_CONSENT', productStatusConsentRes)
    } catch (err) {
      errorHandler({
        toastedMessage: i18n.t('error.default_msg'),
        sentryErrMessage: `FAILED DUE TO ${err.response?.data?.message || err}`
      })
    }
  }
}

const mutations = {
  [SET_DATA_AUTHORIZATION_CONSENT]: (state, value) => {
    state.dataAuthorizationConsent = value
  },
  [SET_PRODUCT_STATUS_CONSENT]: (state, value) => {
    state.productStatusConsent = value
  },
  [RESET_ORDER_CONSENTS](state) {
    common.resetState(initialDataAuthorizationConsentState, state)
    common.resetState(initialProductStatusConsentState, state)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
