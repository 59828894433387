<template>
  <div class="col-12 col-lg-9 px-0">
    <input-autocomplete-v3
      :id="question.question_id"
      v-model="selected"
      :question="question"
      :options-data="optionsData"
      :placeholder="placeholder"
      name-by="text"
      value-by="answer_id"
      :validations="validations"
      @input="$emit('input', $event)"
      @not-listed="$emit('not-listed', $event)"
      @reveal-ids="$emit('reveal-ids', $event)"
      @hide-ids="$emit('hide-ids', $event)"
    />
  </div>
</template>

<script>
import InputAutocompleteV3 from '@/components/ui-components/InputAutocomplete/InputAutocompleteV3.vue'

export default {
  name: 'QuestionAutocomplete',

  components: { InputAutocompleteV3 },

  props: {
    value: {
      type: String,
      required: true
    },

    question: {
      type: Object,
      required: true
    },

    optionsData: {
      type: [Number, Array],
      default: null
    },

    placeholder: {
      type: String,
      default: ''
    },

    validations: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      selected: ''
    }
  },

  mounted() {
    this.selected = this.value
  }
}
</script>
