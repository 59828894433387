<template>
  <div class="select-test-products">
    <select-test-product-v4
      v-for="(product, index) in products"
      :key="product.slug"
      :product="product"
      :is-recommended="hasAnsweredAllQuestions && index === 0"
      :test-details="testDetails"
      :dtc-organization="dtcOrganization"
      :zip="zip"
      :is-commercial-insurance-selected="isCommercialInsuranceSelected"
      :eligibility-response-uuid="eligibilityResponseUuid"
    />
  </div>
</template>

<script>
import SelectTestProductV4 from '@/components/views/SelectTestV4/SelectTestProductV4.vue'

export default {
  name: 'SelectTestProductsV4',

  components: { SelectTestProductV4 },

  props: {
    products: {
      type: Array,
      required: true
    },

    testDetails: {
      type: Array,
      default: () => []
    },

    dtcOrganization: {
      type: Object,
      required: true
    },

    zip: {
      type: String,
      default: ''
    },

    isCommercialInsuranceSelected: {
      type: Boolean,
      default: false
    },

    eligibilityResponseUuid: {
      type: String,
      required: true
    },

    hasAnsweredAllQuestions: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.select-test-products {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
</style>
