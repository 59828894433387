const changeRangeDays = (month, year) => {
  if (typeof month !== 'number' || month < 0 || month > 11) {
    month = 0
  }
  if (typeof year !== 'number') {
    year = 2000
  }
  // The third argument is day, starting with 1. When you pass 0 to the third argument instead,
  // it uses the last day of the previous month. Thus, to get the last day of the current month we
  // increment by 1.
  return new Date(year, month + 1, 0).getDate()
}

export default changeRangeDays
