import { i18n } from '@/plugins/i18n.js'

export const displayAddress = (address, address2, city, state, zip) => {
  const addressFull = [address, address2, city, [state, zip].join(' ')]
    .filter((val) => val)
    .map((val) => val.trim())
    .join(', ')
  return addressFull || i18n.t('not_applicable')
}

export const setGuardianAddressSameAsPatient = (guardianForm, userForm) => {
  const addressFields = ['address', 'address2', 'city', 'state', 'zip']
  addressFields.forEach((field) => {
    guardianForm.address[field] = userForm.address[field]
  })
}

export const clearGuardianAddress = (guardianForm) => {
  const emptyAddress = { address: '', address2: '', city: '', state: '', zip: '' }
  Object.assign(guardianForm.address, emptyAddress)
}

export const onGuardianAddressCheckboxChange = (val, guardianForm, userForm) => {
  if (val) {
    setGuardianAddressSameAsPatient(guardianForm, userForm)
  } else {
    clearGuardianAddress(guardianForm)
  }
}
