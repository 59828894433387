export const RESULT_VIEWER = {
  PDF: 'PDF',
  DYNAMIC: 'DYNAMIC'
}

export const SURVEY_PLACEMENT = {
  KIT_REGISTRATION: 'kitreg',
  RESULT_READY: 'resultready'
}

export const PRODUCT_LINK_ANCHORS = Object.freeze({
  DOCTOR_DISCUSSION_GUIDE: 'post-result-discussion-guide-with-lab-result',
  EFAX_RESULT: 'efax-result',
  EMAIL_RESULT: 'email-result',
  FIND_A_DOCTOR: 'find-a-doctor',
  FIND_A_SPECIALIST: 'find-a-specialist',
  SCHEDULE_FOLLOW_UP_CALL: 'schedule-follow-up-call'
})
