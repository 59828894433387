<i18n src="./locales/lang.json"></i18n>

<template>
  <base-text class="hsa-fsa-info">
    <icon-base
      :params="{
        width: 19,
        height: 18,
        viewBox: '0 0 19 18'
      }"
    >
      <ix-icon-card />
    </icon-base>
    {{ $t('select_test.hsa_fsa_info') }}
  </base-text>
</template>

<script>
import IconBase from '@/components/ui-components/IconBase/IconBase.vue'
import IxIconCard from '@/components/icons/IxIconCard.vue'

export default {
  name: 'HsaFsaInfo',
  components: { IxIconCard, IconBase }
}
</script>

<style scoped lang="scss">
.hsa-fsa-info {
  margin-block-end: $pharma-spacing-4xl;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: $pharma-font-size-text-md;
}
</style>
